import { PhaseStage, UserRole } from '../../../__generated__/globalTypes';
import { EnumLocalStorage } from '../enums/generalEnums';
import { STATUS } from 'react-joyride';

export const isAdmin = (user) =>
  user?.role === UserRole.ADMIN || user?.role === UserRole.SUPER_ADMIN;

export const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === 'production';
export const isStaging = process.env.NEXT_PUBLIC_NODE_ENV === 'staging';
export const isDev = process.env.NEXT_PUBLIC_NODE_ENV === 'localhost';

export const setLastVisitedPhase = (phase: PhaseStage) =>
  window.localStorage.setItem(EnumLocalStorage.LastVisitedDiaryStage, phase);

export const getLastVisitedPhase = (): string =>
  window.localStorage.getItem(EnumLocalStorage.LastVisitedDiaryStage);

export const setSeenChatbot = () =>
  window.localStorage.setItem(EnumLocalStorage.SeenChatbot, 'true');

export const getSeenChatbot = (): string =>
  window.localStorage.getItem(EnumLocalStorage.SeenChatbot);

export const setChatbotSettings = () =>
  window.localStorage.setItem(EnumLocalStorage.SetChatbotSettings, 'true');

export const getChatbotSettings = (): string =>
  window.localStorage.getItem(EnumLocalStorage.SetChatbotSettings);

export const getTours = (): [] =>
  JSON.parse(window?.localStorage?.getItem('toursTaken')) || [];

export const setTour = (prevTours: string[], tour: string) =>
  localStorage.setItem('toursTaken', JSON.stringify([...prevTours, tour]));

export const checkTourFinish = (status: string): boolean => {
  const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

  if (finishedStatuses.includes(status)) {
    return true;
  }
  return false;
};

export const sanitizeInput = (value) => {
  return value.replace(/^\s*'+\s*|\s*'+\s*$/g, '').trim();
};
