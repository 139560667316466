export enum EnumLocalStorage {
  LastVisitedDiaryStage = 'lastVisitedDiaryStage',
  SeenChatbot = 'seenChatbot',
  SetChatbotSettings = 'setChatbotSettings',
}

export enum EnumPrismicSlices {
  SubSection = 'sub_section',
  Tools = 'tools',
  InterestingSources = 'interesting_sources',
  SupportAndHelp = 'support_and_help_section',
}

export enum EnumAuthCookies {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  VerificationToken = 'verificationToken',
  RequestId = 'requestId',
}

export enum EnumProjectPhase {}
