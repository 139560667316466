import { createGlobalStyle } from 'styled-components';
import cssReset from './cssReset';
import '@copilotkit/react-ui/styles.css';

export const GlobalStyle = createGlobalStyle`
  
  ${cssReset}
  
  * {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
  }
`;
