import React, { createContext, useContext, useState, useEffect } from 'react';

const ProjectContext = createContext(null);

export const ProjectProvider = ({ children }) => {
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    const savedProject = localStorage.getItem('activeProject');
    if (savedProject) {
      setActiveProject(savedProject);
    }
  }, []);

  useEffect(() => {
    if (activeProject) {
      localStorage.setItem('activeProject', activeProject);
    }
  }, [activeProject]);

  return (
    <ProjectContext.Provider value={{ activeProject, setActiveProject }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);
